import { historyTypes } from "../types";

export const pushtohistory = (location) => {
  return {
    type: historyTypes.PUSH_TO_HISTORY,

    payload: location,
  };
};
export const uploadhistory = (history) => {
  return {
    type: historyTypes.UPLOAD_HISTORY,
    payload: {
      request: {
        method: "post",
        url: "/MA/WebsiteVisitor/history",


        data: history,
      },
    },
  };
};

export const addtonewsletter = (name, email) => {
  return {
    type: historyTypes.SET_NEWSLETTER,
    payload: {
      request: {
        method: "post",
        url: "/SFA/Query/Subscribe",

        data: { name, email },
      },
    },
  };
};
